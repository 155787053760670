:root {
  --dark-background: #000;
  --light-background: #f1fcf8;
  --charcoal-color: #343434;
  --light-gold-color: #f4d394;
  --gold-color: #b28735;
  --light-grey-color: #f6f6f6;
  --wine-red-color: #790000;
}

body {
  font-family: "Source Sans 3", sans-serif;
  background-color: var(--light-background);
  color: var(--charcoal-color);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cinzel", serif;
}

.app-main-drawer .MuiDrawer-paper {
  justify-content: space-between;
}

.default-picker-full-width {
  width: 100%;
}

/* 
.dark-background {
  background-color: var(--dark-background);
  color: var(--light-grey-color);
}

.light-background {
  background-color: var(--light-background);
  color: var(--charcoal-color);
}

.dark-background p,
.dark-background li,
.dark-background a {
  color: var(--light-grey-color);
}

.light-background p,
.light-background li,
.light-background a {
  color: var(--charcoal-color);
} */
